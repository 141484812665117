import { Card, CardHeader, CardContent, CardTitle } from '@/components/ui/card'
import { Metadata } from '@/components/Metadata'

export function DownloadsPage() {
  return (
    <div className="mt-6 flex w-full flex-col items-center justify-center sm:mt-16">
      <Metadata title="Downloads | Ark" />
      <h1 className="text-center font-serif text-4xl">Downloads</h1>
      <p className="text-muted-foreground mb-8 mt-4 text-center">
        Download the iOS app or Chrome extension to make archiving sites even
        easier.
      </p>
      <div className="flex w-full max-w-screen-md flex-col gap-4 sm:flex-row">
        <a
          href="https://apps.apple.com/us/app/ark-archive-on-bitcoin/id6736919058"
          target="_blank"
          rel="noreferrer"
          className="flex w-full"
        >
          <Card className="w-full">
            <CardHeader>
              <CardTitle className="text-xl">iOS App</CardTitle>
            </CardHeader>
            <CardContent className="flex">
              <img
                src="/ios-app-store-black.svg"
                className="hidden h-12 dark:block"
              />
              <img
                src="/ios-app-store-white.svg"
                className="block h-12 dark:hidden"
              />
            </CardContent>
          </Card>
        </a>
        <a
          href="https://chromewebstore.google.com/detail/ark/cdlocepcfnmjjfhdljhcjmiioaambeoj"
          target="_blank"
          rel="noreferrer"
          className="flex w-full"
        >
          <Card className="w-full">
            <CardHeader>
              <CardTitle className="text-xl">Chrome Extension</CardTitle>
            </CardHeader>
            <CardContent className="flex">
              <img src="/chrome-web-store.png" className="" />
            </CardContent>
          </Card>
        </a>
      </div>
    </div>
  )
}
