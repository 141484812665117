import { LOCAL_STORAGE } from '@/lib/constants'
import { useAtom } from 'jotai'

import { atom } from 'jotai'

interface AudioPlayerState {
  isPlaying: boolean
  progress: number
  volume: number
  duration: number
  playbackRate: number
  url: string | null
  title: string | null
  artist?: string
  /** Optional link that the title should link to */
  titleLink?: string
}

const defaultState: AudioPlayerState = {
  isPlaying: false,
  progress: 0,
  volume: 1,
  duration: 0,
  playbackRate: 1,
  url: null,
  title: null,
  artist: undefined,
  titleLink: undefined,
}

const audioPlayerAtom = atom<AudioPlayerState>(defaultState)
const shouldUpdateSeekAtom = atom(0)

let timeout: NodeJS.Timeout

export function useAudioPlayer() {
  const [audioState, setAudioState] = useAtom(audioPlayerAtom)

  const [shouldUpdateSeek, setShouldUpdateSeek] = useAtom(shouldUpdateSeekAtom)

  const updateState = (newState: Partial<AudioPlayerState>) => {
    setAudioState((prev: AudioPlayerState) => {
      const state = { ...prev, ...newState }

      clearTimeout(timeout)
      timeout = setTimeout(() => {
        updateLocalStorage(state)
      }, 750)

      return state
    })
  }

  const updateLocalStorage = (state: AudioPlayerState) => {
    if (state.url) {
      localStorage.setItem(LOCAL_STORAGE.CURRENTLY_PLAYING_URL, state.url)
      localStorage.setItem(
        LOCAL_STORAGE.audioProgress(state.url),
        JSON.stringify(state),
      )
    } else {
      localStorage.removeItem(LOCAL_STORAGE.CURRENTLY_PLAYING_URL)
    }
  }

  const playUrl = (
    url: string,
    {
      title,
      artist,
      titleLink,
    }: { title: string; artist: string; titleLink?: string },
  ) => {
    const savedState = checkForSavedState(url)

    if (!savedState) {
      updateState({
        ...defaultState,
        url,
        title,
        artist,
        titleLink,
        isPlaying: true,
      })
    }
  }

  const checkForSavedState = (url?: string): AudioPlayerState | null => {
    const u = url ?? localStorage.getItem(LOCAL_STORAGE.CURRENTLY_PLAYING_URL)
    if (!u) return null

    const rawState = localStorage.getItem(LOCAL_STORAGE.audioProgress(u))
    if (!rawState) return null

    try {
      const state = { ...JSON.parse(rawState), isPlaying: !!url }

      setAudioState({
        ...state,

        // Need to clear the progress value and have the player seek to
        // it so the internal ref is updated
        progress: 0,

        // If we're switching to a new url, we need to wait for the new duration
        // to be available from the player `onDuration` callback
        // before seeking to the saved progress
        duration: url ? 0 : state.duration,
      })

      setShouldUpdateSeek(state.progress)
      return state
    } catch {}

    return null
  }

  return {
    ...audioState,
    updateState,
    playUrl,
    checkForSavedState,
    shouldUpdateSeek,
    setShouldUpdateSeek,
  }
}
