import { Env } from '@/lib/env'
import { Helmet } from 'react-helmet'

interface Props {
  title: string
}

export function Metadata(props: Props) {
  const titlePrefix = Env.DEV ? '[DEV] ' : Env.SIGNET ? '[SIGNET] ' : undefined

  const title = `${titlePrefix}${props.title}`

  return <Helmet title={title} />
}
