const calculateTieBreakerDigits = (nonZeroNumber: number) =>
  (255 - nonZeroNumber) / 255

export function getHashScore(hash: string) {
  if (!hash) return 0

  const zeros = countLeadingZeros(hash)

  const nextNonZeroByte = hash.slice(zeros, zeros + 2)
  const nextNonZeroNumber = hexToNumber(nextNonZeroByte)

  return zeros + calculateTieBreakerDigits(nextNonZeroNumber)
}

/** Compare two proofs and return the one with the most leading zeros
 * If both proofs have the same number of leading zeros, compare the next non-zero byte.
 * If both proofs have the same number of leading zeros and the same next non-zero byte, return the first one.
 */
export function getWinningHash(a: string, b: string) {
  const aScore = getHashScore(a)
  const bScore = getHashScore(b)

  if (bScore > aScore) {
    return { winner: b, score: bScore }
  }

  return { winner: a, score: aScore }
}

const hexToNumber = (hex: string) =>
  !hex ? 0 : Number(`0x${hex.padStart(2, '0')}`)

export function countLeadingZeros(hash: string) {
  if (!hash) return 0

  for (let i = 0; i < hash.length; i++) {
    if (hash[i] !== '0') {
      return i
    }
  }
  return hash.length
}

/** Calculate the time left to win a boost given the hashes per second */
export function calculateTimeToWin(
  hashesPerSecond: number,
  targetZeros: number,
) {
  const bitsPerHexChar = 4

  const totalPossibilities = Math.pow(2, targetZeros * bitsPerHexChar)
  const seconds = totalPossibilities / hashesPerSecond
  const minutes = seconds / 60
  const hours = minutes / 60
  const days = hours / 24
  const years = days / 365

  if (years >= 1) {
    return `${years.toFixed(2)} years`
  } else if (days >= 1) {
    return `${days.toFixed(2)} days`
  } else if (hours >= 1) {
    return `${hours.toFixed()} hours`
  } else if (minutes >= 1) {
    return `${minutes.toFixed()} minutes`
  } else {
    return `${seconds.toFixed()} seconds`
  }
}
