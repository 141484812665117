import { buttonVariants } from '@/components/ui/button'
import { Link } from 'react-router-dom'

export function NotFoundPage() {
  return (
    <div className="flex min-h-screen items-center justify-center">
      <div className="px-4 text-center">
        <h1 className="text-4xl font-bold">Not Found</h1>
        <p className="mt-4">The page you're looking for doesn't exist.</p>
        <Link
          to="/"
          className={buttonVariants({ variant: 'theme', className: 'mt-6' })}
        >
          Go back home
        </Link>
      </div>
    </div>
  )
}
