import { countLeadingZeros } from '@ark/utils'
import { SHA256, enc } from 'crypto-js'

export type WorkerStartMessage = {
  type: 'start'
  nonce: Uint8Array
  txid: string
}

export type WorkerStopMessage = {
  type: 'stop'
}

export type WorkerWinnerUpdatedMessage = {
  type: 'winnerUpdated'
  winningHash: string
}

export type WorkerMessage =
  | WorkerStartMessage
  | WorkerStopMessage
  | WorkerWinnerUpdatedMessage

export type MainMessage = MainWinnerMessage | MainProgressMessage

export type MainWinnerMessage = {
  type: 'winner'
  hash: string
  data: string
  txid: string
}

export type MainProgressMessage = {
  type: 'progress'
  currentHashCount: number
}

const generateRandomBytes = (length: number) => {
  const array = new Uint8Array(length)

  return crypto.getRandomValues(array)
}

// Fast hex lookup table
const HEX_CHARS = '0123456789abcdef'.split('')
const HEX_LOOKUP: string[] = new Array(256)
for (let i = 0; i < 256; i++) {
  HEX_LOOKUP[i] = HEX_CHARS[i >> 4] + HEX_CHARS[i & 15]
}

function toHex(data: Uint8Array): string {
  let hex = ''
  for (let i = 0; i < data.length; i++) {
    hex += HEX_LOOKUP[data[i]]
  }
  return hex
}

function hexToUint8Array(hex: string) {
  if (!hex) return new Uint8Array()

  let bytes = new Uint8Array(hex.length / 2)

  for (let i = 0; i < hex.length; i += 2) {
    bytes[i / 2] = parseInt(hex.slice(i, i + 2), 16)
  }

  return bytes
}

/** Increments the last byte of the array
 * Offset is right to left
 */
function incrementUint8Array(arr: Uint8Array, offset: number = 0): boolean {
  let i = arr.length - 1 - offset

  while (i >= 0 && arr[i] === 255) {
    arr[i] = 0
    i--
  }

  if (i >= 0) {
    arr[i]++
    return false
  }

  return true
}

let running = false
let currentHashCount = 0
let txid = ''
let winningZeros = 0

export const PROGRESS_UPDATE_BATCH = 10_000

async function run() {
  if (!txid) throw Error('txid is required')
  const txidBytes = hexToUint8Array(txid)

  const randomBytes = generateRandomBytes(16)
  const nonce = new Uint8Array([...new Uint8Array(16), ...randomBytes])

  const data = new Uint8Array([...txidBytes, ...nonce])

  while (running) {
    const dataHex = toHex(data)
    const hash = SHA256(enc.Hex.parse(dataHex)).toString()
    currentHashCount++

    const zeros = countLeadingZeros(hash)

    if (zeros >= winningZeros) {
      self.postMessage({
        type: 'winner',
        hash,
        data: dataHex,
        txid,
      } as MainMessage)
    }

    incrementUint8Array(data, randomBytes.byteLength)

    if (currentHashCount % PROGRESS_UPDATE_BATCH === 0) {
      self.postMessage({
        type: 'progress',
        currentHashCount,
      } as MainMessage)

      currentHashCount = 0

      // Need this line or else the worker will not stop when commanded
      await new Promise((resolve) => setTimeout(resolve, 0))
    }
  }
}

self.onmessage = (e: MessageEvent<WorkerMessage>) => {
  switch (e.data.type) {
    case 'start':
      running = true
      txid = e.data.txid

      run()
      break
    case 'stop':
      running = false
      break
    case 'winnerUpdated':
      winningZeros = countLeadingZeros(e.data.winningHash)
      break
  }
}
