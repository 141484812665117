import { PriceToggle } from '@/components/PriceToggle'
import { Alert, AlertTitle, AlertDescription } from '@/components/ui/alert'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { api } from '@/lib/api'
import { formatDateWithTime } from '@/lib/formatting'
import { getBlockUrl, getOrdinalExplorerUrl } from '@/lib/utils'
import { IArchive } from '@/types'
import {
  CheckCircleIcon,
  ExternalLinkIcon,
  BitcoinIcon,
  LoaderCircleIcon,
  ArrowRightIcon,
} from 'lucide-react'

interface Props {
  archive: IArchive
  openPaymentModal: () => void
}

export function OnChainDetails(props: Props) {
  const { archive, openPaymentModal } = props

  const { data: btcInfo } = api.ordinals.btcFees.useQuery()

  const hashOnlySaved = archive.hashTx?.txid && !archive.fullTextTx?.txid

  return (
    <Alert variant="theme">
      <CheckCircleIcon className="h-4 w-4" />
      <AlertTitle>Saved on Bitcoin</AlertTitle>
      <AlertDescription>
        <div>
          <div>
            This page{hashOnlySaved ? `'s hash` : ''} is saved on Bitcoin.
          </div>
          {hashOnlySaved ? (
            <Button
              className="mt-2 font-sans"
              variant="theme"
              onClick={openPaymentModal}
            >
              <BitcoinIcon className="mr-1 h-4 w-4" /> Save Full Page
            </Button>
          ) : null}
        </div>
        <Dialog>
          <DialogTrigger className="text-theme mt-4 flex items-center gap-1.5 text-sm underline-offset-4 sm:hover:underline">
            More details
            <ArrowRightIcon className="h-4 w-4" />
          </DialogTrigger>
          <DialogContent className="max-w-screen-md">
            <DialogHeader>
              <DialogTitle>On-Chain Details</DialogTitle>
            </DialogHeader>
            {!btcInfo ? (
              <div className="flex items-center justify-center">
                <LoaderCircleIcon className="h-4 w-4 animate-spin" />
              </div>
            ) : null}
            <div className="mt-4 grid grid-cols-1 gap-x-4 gap-y-2 font-mono text-xs sm:grid-cols-[auto,1fr]">
              {archive.hashTx && btcInfo && (
                <Details
                  type="hash"
                  blockTimestamp={archive.hashTx.blockTimestamp}
                  ordinalId={archive.hashTx.ordinalId}
                  blockHeight={archive.hashTx.blockHeight}
                  blockHash={archive.hashTx.blockHash}
                  feeRate={archive.hashTx.satsPerByte}
                  totalFeeSats={archive.hashTx.totalSats}
                  bitcoinPrice={btcInfo.price}
                />
              )}
              {archive.fullTextTx && btcInfo ? (
                <>
                  {archive.hashTx ? <div className="col-span-2 mt-4" /> : null}
                  <Details
                    type="full-text"
                    blockTimestamp={archive.fullTextTx.blockTimestamp}
                    ordinalId={archive.fullTextTx.ordinalId}
                    blockHeight={archive.fullTextTx.blockHeight}
                    blockHash={archive.fullTextTx.blockHash}
                    feeRate={archive.fullTextTx.satsPerByte}
                    totalFeeSats={archive.fullTextTx.totalSats}
                    bitcoinPrice={btcInfo.price}
                  />
                </>
              ) : null}
              {archive.boostTx && btcInfo ? (
                <>
                  <div className="col-span-2 mt-4" />
                  <Details
                    type="boost"
                    blockTimestamp={archive.boostTx.blockTimestamp}
                    ordinalId={archive.boostTx.ordinalId}
                    blockHeight={archive.boostTx.blockHeight}
                    blockHash={archive.boostTx.blockHash}
                    feeRate={archive.boostTx.satsPerByte}
                    totalFeeSats={archive.boostTx.totalSats}
                    bitcoinPrice={btcInfo.price}
                  />
                </>
              ) : null}
            </div>
          </DialogContent>
        </Dialog>
      </AlertDescription>
    </Alert>
  )
}

function Details(props: {
  type: 'hash' | 'full-text' | 'boost'
  blockTimestamp: number
  ordinalId: string
  blockHeight: number
  blockHash: string
  /** Sats per byte */
  feeRate: number
  totalFeeSats: number
  bitcoinPrice: number
}) {
  const {
    type,
    blockTimestamp,
    ordinalId,
    blockHeight,
    blockHash,
    feeRate,
    bitcoinPrice,
    totalFeeSats,
  } = props

  return (
    <>
      <div className="font-semibold">Type:</div>
      <div>
        <Badge
          variant={
            type === 'full-text'
              ? 'default'
              : type === 'boost'
                ? 'theme'
                : 'secondary'
          }
          className="uppercase"
        >
          {type === 'full-text'
            ? 'Full Text'
            : type === 'boost'
              ? 'Boost'
              : 'Hash Only'}
        </Badge>
      </div>
      <div className="font-semibold">Transaction:</div>
      <a
        href={getOrdinalExplorerUrl(ordinalId)}
        target="_blank"
        rel="noreferrer noopener"
        className="text-theme flex items-center gap-1 justify-self-start break-all text-xs underline-offset-4 hover:underline"
      >
        {ordinalId} <ExternalLinkIcon className="h-4 w-4 flex-shrink-0" />
      </a>
      <div className="font-semibold">Block Timestamp:</div>
      <div>{formatDateWithTime(blockTimestamp * 1000)}</div>

      <div className="font-semibold">Block Hash:</div>
      <div className="break-words">{blockHash}</div>
      <div className="font-semibold">Block Height:</div>
      <a
        href={getBlockUrl(blockHeight)}
        target="_blank"
        rel="noreferrer noopener"
        className="text-theme flex items-center gap-1 justify-self-start break-all text-xs underline-offset-4 hover:underline"
      >
        {blockHeight} <ExternalLinkIcon className="h-4 w-4 flex-shrink-0" />
      </a>
      <div className="font-semibold">Fee Rate:</div>
      <div>{feeRate} sats/byte</div>
      <div className="font-semibold">Total Fees:</div>
      <PriceToggle
        sats={totalFeeSats}
        bitcoinPrice={bitcoinPrice}
        className="cursor-pointer"
      />
    </>
  )
}
