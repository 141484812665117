import { EventChannels } from 'backend/src/lib/event-channels'

import { useState, useEffect } from 'react'

const getSseUrl = (url: string) => `/api/sse?url=${url}`

export function useSse(url: string | undefined) {
  const [sse, setSse] = useState<EventSource | null>(null)

  useEffect(() => {
    if (!url) return

    const sse = new EventSource(getSseUrl(url))
    setSse(sse)

    return () => {
      sse.close()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url])

  const addEventListener = <Event extends keyof EventChannels>(
    event: Event,
    cb: (data: EventChannels[Event]) => void,
  ) => {
    sse?.addEventListener(event, ({ data }) => {
      cb(JSON.parse(data))
    })
  }

  const clearEventListeners = () => {
    sse?.close()

    if (url) {
      const newSse = new EventSource(getSseUrl(url))
      setSse(newSse)
    }
  }

  return { addEventListener, clearEventListeners }
}
