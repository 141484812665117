import { Button } from '@/components/ui/button'
import { Card, CardContent } from '@/components/ui/card'
import { useBoost } from '@/hooks/useBoost'
import { useSse } from '@/hooks/useSse'
import { api } from '@/lib/api'
import { EMAILS, LOCAL_STORAGE, PAYMENT_PAYMAIL } from '@/lib/constants'
import { cn } from '@/lib/utils'
import {
  calculateTimeToWin,
  countLeadingZeros,
  getHashScore,
  getWinningHash,
} from '@ark/utils'
import { formatDuration, intervalToDuration } from 'date-fns'
import {
  ArrowLeftIcon,
  BitcoinIcon,
  ChevronsUpIcon,
  ChevronUpIcon,
  CopyIcon,
  Pause,
  Play,
  XIcon,
} from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import { z } from 'zod'
import {
  MainMessage,
  PROGRESS_UPDATE_BATCH,
  WorkerStartMessage,
  WorkerWinnerUpdatedMessage,
} from './boost-worker'
import PayPlugin from './PayPlugin'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from './ui/alert-dialog'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from './ui/dialog'
import { Progress } from './ui/progress'
import { toast } from './ui/use-toast'
import { Link } from 'react-router-dom'
import { AppRoutes } from '@/routes'

let timerInterval: NodeJS.Timeout | null = null
let statsInterval: NodeJS.Timeout | null = null

const POLL_LATEST_BOOST_INTERVAL = 1_000 * 60 * 2 // 2 minutes

const STATS_REFRESH_INTERVAL = PROGRESS_UPDATE_BATCH / 20

const bestHashLocalStorageSchema = z.object({
  hash: z.string(),
  txid: z.string(),
  data: z.string(),
})

export function Boost() {
  const { minimized, setMinimized, archive, setArchive } = useBoost()

  const [fetchingLatestHash, setFetchingLatestHash] = useState(false)
  const [running, setRunning] = useState(false)
  const [closeDialog, setCloseDialog] = useState(false)
  const [bestHash, setBestHash] = useState('')
  const [bestData, setBestData] = useState('')
  const [hashCount, setHashCount] = useState(0)
  const [hashRate, setHashRate] = useState(0)
  const [runningTime, setRunningTime] = useState(0)
  const [view, setView] = useState<'hash' | 'data'>('hash')

  const workers = useRef<Worker[]>([])

  // Use refs for tracking stats for much better performance
  // we'll update actual state values in a setInterval
  const _bestHash = useRef('')
  const _bestData = useRef('')
  const _lastUpdateTime = useRef(Date.now())
  const _recentHashCount = useRef(0)
  const _hashCount = useRef(0)

  const { addEventListener, clearEventListeners } = useSse(archive?.url)

  useEffect(() => {
    if (!archive) {
      clearEventListeners()
      return
    }

    addEventListener('boostPaymentSuccess', (data) => {
      if (data.hash === bestHash) {
        deleteBestHashLocalStorage()

        setBestHash('')
        setBestData('')
        setHashCount(0)
        _bestData.current = ''
        _hashCount.current = 0
        _recentHashCount.current = 0

        toast({
          title: 'Boost payment successful!',
          description: `Your boost is currently being saved on chain. Once completed, you'll be able to view the TX details on the archive page.`,
        })
      }
    })

    return () => {
      clearEventListeners()
    }
  }, [archive])

  useEffect(() => {
    if (!archive) {
      reset()
      setRunning(false)
      return
    }

    const updateState = async () => {
      reset()

      await checkLatestScore()

      const saved = getSavedBestHash(archive.txid)
      if (saved) {
        setBestHash(saved.hash)
        setBestData(saved.data)
        _bestData.current = saved.data
        _bestHash.current = saved.hash
      }

      if (workers.current.length) {
        broadcastWinner()
      }
    }

    updateState()
  }, [archive])

  useEffect(() => {
    setupWorkers()

    return () => {
      cleanupWorkers()
    }
  }, [])

  useEffect(() => {
    if (!running) {
      setHashRate(0)
    }

    if (running && archive) {
      workers.current.forEach((worker) => {
        worker.postMessage({
          type: 'start',
          txid: archive.txid,
        } as WorkerStartMessage)
      })

      timerInterval = setInterval(() => {
        setRunningTime((prev) => prev + 1)
      }, 1000)

      statsInterval = setInterval(() => {
        updateStats()
      }, STATS_REFRESH_INTERVAL)
    } else {
      timerInterval && clearInterval(timerInterval)
      statsInterval && clearInterval(statsInterval)
    }

    return () => {
      timerInterval && clearInterval(timerInterval)
      statsInterval && clearInterval(statsInterval)
    }
  }, [running, archive])

  useEffect(() => {
    const pollLatestBoostInterval = setInterval(
      checkLatestScore,
      POLL_LATEST_BOOST_INTERVAL,
    )

    return () => {
      clearInterval(pollLatestBoostInterval)
    }
  }, [])

  const setupWorkers = () => {
    const workerCount = navigator.hardwareConcurrency
    // const workerCount = 1
    console.log(
      `%cSetting up ${workerCount} workers`,
      'color: white; background: green; padding: 2px 4px; border-radius: 4px;',
    )

    for (let i = 0; i < workerCount; i++) {
      const worker = new Worker(new URL('./boost-worker.ts', import.meta.url), {
        type: 'module',
      })
      worker.onmessage = handleWorkerMessage
      workers.current.push(worker)
    }
  }

  const cleanupWorkers = () => {
    if (!workers.current.length) return
    console.log(
      `%cCleaning up ${workers.current.length} workers`,
      'color: white; background: blue; padding: 2px 4px; border-radius: 4px;',
    )
    workers.current.forEach((worker) => {
      worker.postMessage({ type: 'stop' })
      worker.terminate()
    })
    workers.current = []
  }

  const handleWorkerMessage = (e: MessageEvent<MainMessage>) => {
    if (!archive?.txid) return

    if (e.data.type === 'progress') {
      _hashCount.current += e.data.currentHashCount
      _recentHashCount.current += e.data.currentHashCount
    }

    if (e.data.type === 'winner') {
      const { winner, score } = getWinningHash(_bestHash.current, e.data.hash)
      if (winner === e.data.hash && score >= 1) {
        _bestHash.current = e.data.hash
        _bestData.current = e.data.data

        saveBestHash({
          hash: _bestHash.current,
          txid: e.data.txid,
          data: _bestData.current,
        })

        broadcastWinner()
      }
    }
  }

  const updateStats = () => {
    if (!_recentHashCount.current) return

    setHashCount(_hashCount.current)
    setBestHash(_bestHash.current)
    setBestData(_bestData.current)

    const now = Date.now()
    const timeSpan = (now - _lastUpdateTime.current) / 1000

    setHashRate(Math.round(_recentHashCount.current / timeSpan))

    _lastUpdateTime.current = now
    _recentHashCount.current = 0
  }

  const broadcastWinner = () => {
    workers.current.forEach((worker) => {
      worker.postMessage({
        type: 'winnerUpdated',
        winningHash: _bestHash.current,
      } as WorkerWinnerUpdatedMessage)
    })
  }

  const getSavedBestHash = (txid: string) => {
    const raw = localStorage.getItem(LOCAL_STORAGE.bestBoostHash(txid))
    if (!raw) return null

    const parsed = bestHashLocalStorageSchema.safeParse(JSON.parse(raw))
    if (!parsed.success) return null

    return parsed.data
  }

  const saveBestHash = (hash: z.infer<typeof bestHashLocalStorageSchema>) => {
    localStorage.setItem(
      LOCAL_STORAGE.bestBoostHash(hash.txid),
      JSON.stringify(hash),
    )
  }

  const deleteBestHashLocalStorage = () => {
    if (!archive?.txid) throw Error('No txid')

    localStorage.removeItem(LOCAL_STORAGE.bestBoostHash(archive.txid))
  }

  const reset = () => {
    setBestHash('')
    setBestData('')
    setHashCount(0)
    setHashRate(0)
    setRunningTime(0)

    _bestHash.current = ''
    _recentHashCount.current = 0
    _lastUpdateTime.current = Date.now()
    _bestData.current = ''
  }

  const checkLatestScore = async () => {
    let score = 0

    if (!archive) {
      return score
    }

    try {
      setFetchingLatestHash(true)

      const res = await fetch(`/api/boost?url=${archive.url}`)

      // Empty string if no boost found
      const data: { hash: string } = await res.json()

      const targetChanged =
        data.hash && archive.hash && data.hash !== archive.hash ? true : false

      if (targetChanged) {
        toast({
          title: 'Newer boost found!',
          description: 'Target score has been updated.',
        })

        setArchive({
          ...archive,
          hash: data.hash,
        })

        score = getHashScore(data.hash)
      }
    } finally {
      setFetchingLatestHash(false)
    }

    return score
  }

  const toggleRunning = () => {
    setRunning((prev) => !prev)
  }

  const winning = !bestHash
    ? false
    : archive?.hash
      ? getWinningHash(archive.hash, bestHash).winner === bestHash
      : true

  const [showPayment, setShowPayment] = useState(false)

  const handleSave = async () => {
    setRunning(false)

    const latestScore = await checkLatestScore()

    if (score < latestScore) {
      toast({
        title: 'Newer boost found!',
        description: 'Target score has been updated.',
      })
    } else {
      setShowPayment((prev) => !prev)
    }
  }

  const closeBoostDialog = () => {
    setRunning(false)
    setArchive(null)
  }

  const score = getHashScore(bestHash)

  return (
    <div className="fixed inset-x-2 bottom-2 z-50 flex justify-end">
      {/* Minimized */}
      <div
        className={`bg-card text-card-foreground flex w-full max-w-2xl items-center justify-between rounded-lg border px-3 py-2 shadow-lg transition-all ${cn({ 'translate-y-full opacity-0': !minimized })}`}
      >
        <div
          onClick={() => setMinimized(false)}
          className="flex w-full flex-1 cursor-pointer items-center gap-4"
        >
          <div className="flex items-center gap-2">
            <span className="flex items-center gap-2">
              <span
                className={`${cn({ 'animate-pulse text-green-500': running, 'text-secondary': !running })}`}
              >
                ●
              </span>{' '}
              Boost
            </span>
          </div>
          <div className="text-muted-foreground flex items-center gap-2 text-sm">
            <ChevronsUpIcon className="h-4 w-4" />
            <span>
              <span className="hidden sm:inline">Score:</span>{' '}
              {score.toFixed(2)}
            </span>
            <span>•</span>
            <span>{hashRate.toLocaleString()} hashes/s</span>
          </div>
          <Button variant="ghost" size="sm" className="h-auto p-0">
            <ChevronUpIcon className="h-5 w-5" />
          </Button>
        </div>
        <Button
          variant="ghost"
          className="h-auto p-0"
          onClick={() => {
            if (running) {
              setCloseDialog(true)
            } else {
              closeBoostDialog()
            }
          }}
        >
          <XIcon className="size-5" />
        </Button>
        <AlertDialog open={closeDialog} onOpenChange={setCloseDialog}>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Stop Boost?</AlertDialogTitle>
              <AlertDialogDescription>
                This will stop any current boosts and hide the boost dialog. You
                can always re-open it by clicking the boost icon in the archive
                page.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction onClick={closeBoostDialog}>
                Continue
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </div>

      <Dialog open={!minimized} onOpenChange={(open) => setMinimized(!open)}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Boost</DialogTitle>
            <DialogDescription>
              Boost content visibility by contributing computing power.
            </DialogDescription>
          </DialogHeader>

          {showPayment && archive?.url && bestHash ? (
            <div>
              <div className="flex">
                <Button variant="ghost" onClick={() => setShowPayment(false)}>
                  <ArrowLeftIcon className="h-4 w-4" /> Back
                </Button>
              </div>

              <div className="mt-4 flex justify-center">
                <Payment url={archive.url} hash={bestHash} data={bestData} />
              </div>
            </div>
          ) : (
            <>
              <Card className="overflow-hidden">
                <CardContent className="space-y-4 pt-6">
                  {archive && (
                    <div className="mb-8">
                      <Link
                        to={AppRoutes.buildArchiveRoute(archive.url)}
                        className="truncate text-sm font-medium"
                      >
                        {archive.title}
                      </Link>
                      <div className="text-muted-foreground text-xs">
                        {archive.url}
                      </div>
                    </div>
                  )}
                  <div className="flex items-center justify-between gap-2">
                    {running ? (
                      <Button onClick={toggleRunning} className="w-32">
                        <Pause className="mr-2 h-4 w-4" /> Pause
                      </Button>
                    ) : (
                      <Button onClick={toggleRunning} className="w-32">
                        <Play className="mr-2 h-4 w-4" /> Start
                      </Button>
                    )}
                    <Button
                      variant="theme"
                      disabled={!bestHash || !winning || fetchingLatestHash}
                      className="gap-2"
                      onClick={handleSave}
                    >
                      <BitcoinIcon className="h-5 w-5" />

                      {fetchingLatestHash ? 'Checking status...' : 'Save'}
                    </Button>
                  </div>

                  <div className="bg-secondary space-y-3 rounded-lg p-4">
                    <div className="flex items-center justify-between text-sm">
                      <span className="flex items-center gap-2">
                        <ChevronsUpIcon className="h-4 w-4" />
                        Boost Score:
                      </span>
                      <span className="font-bold">
                        <span
                          className={cn({
                            'text-green-500': winning,
                          })}
                        >
                          {score.toFixed(2)}
                        </span>{' '}
                        {archive?.hash &&
                          `/ ${getHashScore(archive.hash).toFixed(2)}`}
                      </span>
                    </div>
                    <Progress
                      value={score}
                      max={20}
                      className="bg-background h-2"
                    />
                    {archive?.hash && !winning && (
                      <div className="text-muted-foreground -mt-1 text-xs">
                        Estimated time to win:{' '}
                        {hashRate > 0 && (
                          <span>
                            {calculateTimeToWin(
                              hashRate,
                              countLeadingZeros(archive.hash),
                            )}
                          </span>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="grid gap-2">
                    <div className="flex justify-between text-sm">
                      <span className="text-muted-foreground font-medium">
                        Processing Speed:
                      </span>
                      <span>{hashRate.toLocaleString()} hashes/sec</span>
                    </div>
                    <div className="flex justify-between text-sm">
                      <span className="text-muted-foreground font-medium">
                        Total Hashes:
                      </span>
                      <span>{hashCount.toLocaleString()}</span>
                    </div>
                    <div className="flex justify-between text-sm">
                      <span className="text-muted-foreground font-medium">
                        Running Time:
                      </span>
                      <span>
                        {' '}
                        {runningTime &&
                          formatDuration(
                            intervalToDuration({
                              start: 0,
                              end: runningTime * 1000,
                            }),
                            { delimiter: ', ' },
                          )}
                      </span>
                    </div>
                  </div>

                  <div className="space-y-2">
                    <div className="flex items-center justify-between gap-2 text-sm font-medium">
                      <span>
                        Your Best {view === 'hash' ? 'Hash' : 'Data'}:
                      </span>
                      <Button
                        variant="ghost"
                        size="icon"
                        className="h-auto w-auto p-0 hover:bg-transparent"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            view === 'hash' ? bestHash : bestData,
                          )
                          toast({
                            title: 'Copied to clipboard',
                          })
                        }}
                      >
                        <CopyIcon className="h-4 w-4" />
                      </Button>
                    </div>
                    <div
                      onClick={() => {
                        setView((prev) => (prev === 'hash' ? 'data' : 'hash'))
                      }}
                      className="bg-secondary cursor-pointer break-all rounded-md p-2 font-mono text-xs"
                    >
                      {view === 'hash'
                        ? bestHash
                        : (bestData ?? 'None found yet')}
                    </div>
                  </div>

                  <div className="text-muted-foreground text-xs">
                    The longer you boost, the higher chance of finding a better
                    hash and increasing content's visibility.{' '}
                    {/* TODO: Link to relevant FAQ section */}
                    {/* <Link
                      to="/how-it-works"
                      className="text-theme ml-1 inline-flex items-center gap-1"
                    >
                      How it works <ArrowRightIcon className="size-3" />
                    </Link> */}
                  </div>
                </CardContent>
              </Card>
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  )
}

function Payment({
  url,
  hash,
  data,
}: {
  url: string
  hash: string
  data: string
}) {
  const { data: fee } = api.ordinals.estimateBoost.useQuery({
    url,
    data,
  })

  const callbackUrl = new URL(
    `${window.location.origin}/api/callbacks/payment/boost`,
  )

  callbackUrl.searchParams.set('hash', hash)
  callbackUrl.searchParams.set('data', data)
  return (
    <div>
      {fee?.usd && (
        <PayPlugin
          paymail={PAYMENT_PAYMAIL}
          callbackUrl={callbackUrl.toString()}
          sessionId={url}
          productName={`Ark Boost - ${url}`}
          price={fee.usd}
          receiptEmail={EMAILS.RECEIPT}
        />
      )}
    </div>
  )
}
