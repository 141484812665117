import { Button, buttonVariants } from '@/components/ui/button'
import { toast } from '@/components/ui/use-toast'
import { useAnalytics } from '@/hooks/useAnalytics'
import { api } from '@/lib/api'
import { AppRoutes } from '@/routes'
import {
  ArrowRightIcon,
  ChevronsDownIcon,
  LoaderCircleIcon,
} from 'lucide-react'
import { useState, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FeedPage } from '../Feed/FeedPage'
import { Input } from '@/components/ui/input'
import { cn } from '@/lib/utils'
import { CreateRawArchive } from './components/CreateRawArchive'
import { useDidScroll } from '@/hooks/useDidScroll'

export function HomePage() {
  const navigate = useNavigate()
  const analytics = useAnalytics()

  const mutation = api.archives.scrape.useMutation({
    onSuccess: async (url) => {
      await new Promise((resolve) => setTimeout(resolve, 1500))

      navigate(AppRoutes.buildArchiveRoute(url))
    },
    onError: (error) => {
      toast({
        title: 'Error Archiving',
        description: error.message,
        variant: 'destructive',
      })
    },
  })

  const [url, setUrl] = useState('')

  const browseRef = useRef<HTMLElement>(null)

  const scrollToBrowse = () => {
    browseRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const showOverlay = !useDidScroll({ threshold: 20 })

  return (
    <div className="flex flex-col">
      <section className="bg-background text-foreground flex h-[50vh] w-full flex-col items-center pt-36 sm:h-[58vh]">
        <div className="flex w-full flex-1 flex-col items-center justify-center text-center">
          <h1 className="lg:leading-tighter font-serif text-3xl sm:text-4xl md:text-5xl xl:text-[3.4rem] 2xl:text-[3.75rem]">
            Archive the Internet on
            <br />
            Bitcoin
          </h1>
          <p className="text-muted-foreground mt-2 max-w-2xl text-lg">
            The permanent internet archive. Save today, access forever.
          </p>
          <div className="mt-6 flex w-full max-w-screen-sm flex-col gap-1.5">
            <form
              className="flex flex-1 items-center justify-between overflow-hidden rounded-md border border-indigo-500"
              onSubmit={(e) => {
                analytics.sendEvent('page_scraped', { url })

                e.preventDefault()

                mutation.mutate({ url })
              }}
            >
              <Input
                type="text"
                placeholder="Enter a URL"
                className="flex-1 rounded-md p-2 focus-visible:rounded-r-none"
                onChange={(e) => setUrl(e.target.value)}
                autoFocus
                value={url}
              />
              <Button
                type="submit"
                variant="theme"
                className="rounded-none px-5 text-base disabled:opacity-100"
                disabled={mutation.isPending || !url}
              >
                {mutation.isPending ? 'Archiving...' : 'Archive'}
                {mutation.isPending && (
                  <LoaderCircleIcon className="animate-spin" />
                )}
              </Button>
            </form>
            <CreateRawArchive />
          </div>
        </div>
      </section>
      <section className="min-h-[40rem] pt-12" ref={browseRef}>
        <FeedPage
          pageSize={8}
          disableEndlessScroll
          hideFilters
          disableViewUrlChanges
          hideViewToggle={showOverlay}
        />
        <div className="mt-4 flex justify-center">
          <Link
            to={AppRoutes.FEED}
            className={`${buttonVariants({ variant: 'ghost' })} flex items-center gap-1.5`}
          >
            View all <ArrowRightIcon className="h-5 w-5" />
          </Link>
        </div>
      </section>
      <div
        className={`pointer-events-none fixed inset-0 z-10 hidden h-[100dvh] min-h-screen flex-col justify-end transition-opacity duration-500 sm:flex ${cn(
          {
            'opacity-0': !showOverlay,
          },
        )}`}
      >
        <div
          className={`to-background via-background/70 pointer-events-none h-[15vh] w-full bg-gradient-to-b from-transparent transition-opacity duration-500 sm:h-[25vh]`}
        />
      </div>
      <div
        className={`fixed inset-x-0 bottom-0 z-20 hidden justify-center pb-1.5 transition-opacity duration-500 sm:flex ${cn({ 'pointer-events-none opacity-0': !showOverlay })}`}
      >
        <button onClick={scrollToBrowse} className={`text-primary`}>
          <ChevronsDownIcon className="stroke-primary h-6 w-6" />
        </button>
      </div>
    </div>
  )
}
