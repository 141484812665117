import { Routes as ReactRouterRoutes, Route } from 'react-router-dom'
import { Layout } from './Layout'
import { HomePage } from './pages/Home/HomePage'
import { ArchivePage } from './pages/Archive/ArchivePage'
import { FeedPage } from './pages/Feed/FeedPage'
import { AdminPage } from './pages/Archive/Admin/AdminPage'
import { AboutPage } from './pages/AboutPage'
import { StatsPage } from './pages/StatsPage'
import { PrivacyPolicyPage } from './pages/PrivacyPolicyPage'
import { DownloadsPage } from './pages/DownloadsPage'
import { NotFoundPage } from './pages/NotFoundPage'

const ROUTES = {
  HOME: '/',
  NEW_ARCHIVE: '/archive/new',
  /** @deprecated use `buildArchiveRoute` instead */
  ARCHIVE: '/archive',
  /** @deprecated */
  BROWSE: '/browse',
  FEED: '/feed',
  ADMIN: '/admin/:adminSecret',
  ABOUT: '/about',
  CONTACT: '/contact',
  STATS: '/stats',
  PRIVACY_POLICY: '/privacy-policy',
  DOWNLOADS: '/downloads',
}

// eslint-disable-next-line react-refresh/only-export-components
export const AppRoutes = {
  ...ROUTES,
  buildArchiveRoute: (url: string) => `${ROUTES.ARCHIVE}?url=${url}`,
}

export function Routes() {
  return (
    <ReactRouterRoutes>
      <Route element={<Layout />}>
        <Route path={ROUTES.HOME} element={<HomePage />} />
        <Route path={ROUTES.ARCHIVE} element={<ArchivePage />} />
        <Route path={ROUTES.BROWSE} element={<FeedPage />} />
        <Route path={ROUTES.FEED} element={<FeedPage />} />
        <Route path={ROUTES.ADMIN} element={<AdminPage />} />
        <Route path={ROUTES.ABOUT} element={<AboutPage />} />
        <Route path={ROUTES.STATS} element={<StatsPage />} />
        <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicyPage />} />
        <Route path={ROUTES.DOWNLOADS} element={<DownloadsPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </ReactRouterRoutes>
  )
}
