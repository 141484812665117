export const PAYMENT_PAYMAIL = '2mgqybiipklrsb3coqg9x4ybzwx@canonic.xyz' // balmy-bird@canonic.xyz
// export const DONATION_PAYMAIL = '2d9hnbx1bdlkamr8pvkm3myuqntt@canonic.xyz' // memory@canonic.xyz

export const EMAILS = {
  CONTACT: 'contact@ark.page',
  RECEIPT: 'archive-receipts@ark.page',
} as const

export const SATS_PER_BITCOIN = 100_000_000

export const LOCAL_STORAGE = {
  UI_THEME: 'ui_theme',
  CURRENTLY_PLAYING_URL: 'currently_playing_url',
  CURRENT_BOOST: 'current_boost',
  audioProgress: (url: string) => `audio_progress_${url}`,
  bestBoostHash: (txid: string) => `best_boost_${txid}`,
}
