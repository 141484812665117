import { LOCAL_STORAGE } from '@/lib/constants'
import { atom, useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

type Archive = {
  url: string
  title: string
  txid: string
  hash?: string
}

const archiveAtom = atomWithStorage<Archive | null>(
  LOCAL_STORAGE.CURRENT_BOOST,
  null,
)

const minimizedAtom = atom(true)

export function useBoost() {
  const [archive, _setArchive] = useAtom(archiveAtom)
  const [minimized, setMinimized] = useAtom(minimizedAtom)

  const setArchive = (value: Archive | null) => {
    _setArchive(value)

    if (value) {
      setMinimized(false)
    }
  }

  return {
    minimized,
    setMinimized,
    archive,
    setArchive,
  }
}
