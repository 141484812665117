import { useState } from 'react'
import { Link, Outlet } from 'react-router-dom'
import { AppRoutes } from './routes'
import { Toaster } from './components/ui/toaster'
import { Search } from './components/Search'
import { FeedbackButton } from './components/FeedbackButton'
import { ThemeToggle } from './components/ThemeToggle'
import { DownloadIcon, SearchIcon } from 'lucide-react'
import { cn } from './lib/utils'
import { useDidScroll } from './hooks/useDidScroll'
import { buttonVariants } from './components/ui/button'
import { AudioPlayer } from './components/AudioPlayer'
import { Boost } from './components/Boost'
import { useBoost } from './hooks/useBoost'

const isIos = /iPad|iPhone|iPod/.test(navigator.userAgent)
const isChrome = !!window.chrome

export function Layout() {
  const [showSearch, setShowSearch] = useState(false)
  const { archive: boostArchive } = useBoost()
  const didScroll = useDidScroll({ threshold: 20 })

  return (
    <div className="flex min-h-dvh w-full flex-col">
      <header
        className={cn(
          'sticky inset-x-0 top-0 z-50 flex flex-col items-center justify-between gap-2 border-b px-4 py-2 transition-colors sm:flex-row lg:px-6',
          {
            'border-border bg-background': didScroll,
            'border-transparent': !didScroll,
          },
        )}
      >
        <Link to={AppRoutes.HOME}>
          <img src={`/logo-black.png`} alt="Ark" className="h-6 dark:hidden" />
          <img
            src={`/logo-white.png`}
            alt="Ark"
            className="hidden h-6 dark:block"
          />
        </Link>
        <div className="mt-2 flex w-full flex-1 flex-col-reverse items-center justify-end sm:mt-0 sm:flex-row sm:gap-4">
          <div className="flex w-full flex-1">
            <div
              className={cn({
                hidden: !showSearch,
                'w-full justify-center sm:flex': true,
              })}
            >
              <Search />
            </div>
          </div>
          <nav className="text flex items-center gap-4">
            <Link to={AppRoutes.FEED}>Feed</Link>
            <Link to={AppRoutes.STATS}>Stats</Link>
            <Link to={AppRoutes.ABOUT}>About</Link>
            {(isIos || isChrome) && <DownloadLink />}
            <button
              onClick={() => setShowSearch(!showSearch)}
              className="block sm:hidden"
            >
              <SearchIcon className="h-5 w-5" />
            </button>
          </nav>
        </div>
      </header>
      <main className="flex flex-1 flex-col px-4 pb-20 md:px-6">
        <Outlet />
      </main>
      <footer className="flex flex-col items-center justify-center gap-4 px-4 py-4 sm:flex-row md:px-6">
        <Link
          to={AppRoutes.PRIVACY_POLICY}
          className="text-primary text-sm font-medium"
        >
          Privacy Policy
        </Link>
        <div className="hidden sm:block">|</div>
        <Link
          to={AppRoutes.DOWNLOADS}
          className="text-primary text-sm font-medium"
        >
          Downloads
        </Link>
        <div className="hidden sm:block">|</div>
        <FeedbackButton />
        <div className="hidden sm:block">|</div>
        <ThemeToggle />
      </footer>
      <AudioPlayer />
      {boostArchive && <Boost />}
      <Toaster />
    </div>
  )
}

const DownloadLink = () => {
  return (
    <Link
      to={AppRoutes.DOWNLOADS}
      className={buttonVariants({
        size: 'icon',
        variant: 'secondary',
        className: 'relative overflow-hidden bg-transparent',
      })}
    >
      <div className="animate-gradient from-background absolute inset-0 bg-gradient-to-tr to-indigo-300 bg-[length:200%_200%]" />
      <div className="absolute inset-0 flex items-center justify-center">
        <DownloadIcon className="h-4 w-4" />
      </div>
    </Link>
  )
}
