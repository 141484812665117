export const Env = {
  DEV: import.meta.env.DEV,
  SIGNET: import.meta.env.PROD && import.meta.env.VITE_SIGNET === 'true',
  PROD: import.meta.env.PROD && import.meta.env.VITE_SIGNET !== 'true',
  get POSTHOG_ENABLED() {
    return this.PROD
  },
  get BASE_URL() {
    return this.SIGNET ? 'https://signet.ark.page' : 'https://ark.page'
  },

  get ORDINALS_EXPLORER_BASE_URL() {
    return this.SIGNET ? 'https://signet.ordinals.com' : 'https://ordiscan.com'
  },

  get BLOCK_EXPLORER_BASE_URL() {
    return this.SIGNET
      ? 'https://mempool.space/signet'
      : 'https://mempool.space'
  },
}
